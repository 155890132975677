<template>
    <div class="library">
        <Navbar></Navbar>
        <div class="library_gather">
            <div class="swiper">
                <img :src="genImgUrl(baseUrl,imgUrl,'@t:1920x1920>')" alt="">
            </div>
            <div class="entrepot" :class="{m_entrepot:$store.state.screenType}">
                <!---->
                <!---->
                <!---->
                <div class="entrepot_title">
                    <span>{{ $t('Nav[4].name').toUpperCase() }}</span>
                    <span></span>
                    <span>&nbsp; >&nbsp;</span>
                    <span>{{ $t('Nav[4].menuList[2]').toUpperCase() }}</span>
                    <span>&nbsp; >&nbsp;</span>
                    <span v-show="this.$route.query.type==='视频资料'">{{ $t('Media.library[0]').toUpperCase() }}</span>
                    <span v-show="this.$route.query.type==='企业图片'">{{ $t('Media.library[1]').toUpperCase() }}</span>
                    <span v-show="this.$route.query.type==='活动图集'">{{ $t('Media.library[2]').toUpperCase() }}</span>
                </div>
                <!---->
                <!---->
                <!---->
                <div class="entrepot_hot" v-show="this.$route.query.type!=='视频资料'">
                    <div class="entrepot_hot_items" v-for="(value,index) in list" :key="index">
                        <span class="hot_title">
                            {{ value.cityName }}
                        </span>
                        <div class="hot-content">
                            <div class="items" v-for="(val,i) in value.list">
                                <div class="image">
                                    <img :src="genImgUrl(baseUrl,val.photoUrl)" alt="">
                                </div>
                                <div class="title">{{ val.descr }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!---->
                <!---->
                <!---->
                <div class="entrepot_hot" v-if="this.$route.query.type==='视频资料'">
                    <div class="entrepot_hot_video" v-for="(value,index) in list" :key="index">
                        <span class="hot_title"> {{ value.cityName }}</span>
                        <div class="hot-content">
                            <div class="video" v-for="(val,i) in value.list">
                                <div class="video_video">
                                    <div class="oval"></div>
                                    <video-player :options="value.video[i]" class="player"></video-player>
                                    <!--<img :src="genImgUrl(baseUrl,val.photoUrl)" alt="">-->
                                </div>
                                <div class="video_title">
                                    {{ val.descr }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footers></Footers>
    </div>
</template>

<script>
import Navbar from '../../components/Nav/Navbar'
import Footers from '../../components/Footer/Footers'
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'
import { imgUrl as genImgUrl } from "@/utils/urlutils"
import { mapState } from 'vuex'

export default {
    name: "Library",
    components: {
        Navbar,
        Footers,
        videoPlayer
    },
    data () {
        return {
            list: {},
            title: '媒体',
            title1: '媒体资料库',
            //index: -1,
            //playerOptions: {
            //    height: 400,
            //    playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
            //    autoplay: false, // 如果true,浏览器准备好时开始回放。
            //    muted: false, // 默认情况下将会消除任何音频。
            //    loop: false, // 导致视频一结束就重新开始。
            //    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            //    language: 'zh-CN',
            //    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            //    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            //    sources: [
            //        {
            //            type: 'video/mp4', // 这里的种类支持很多种
            //            src: require('../../assets/飞书20230106-155533.mp4') // url地址
            //        }
            //    ],
            //    poster: '', // 封面地址
            //    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
            //    controlBar: {
            //        timeDivider: true,
            //        durationDisplay: true,
            //        remainingTimeDisplay: false,
            //        fullscreenToggle: true // 全屏按钮
            //    }
            //},
            meta: {
                Descript: "VSPN是全球最大的电竞社区，拥有全球最顶尖的游戏团队",
                Keywords: "VSPN,电竞,中国电竞",
                Title: "VSPN电竞官方首页 英雄电竞"
            },
            imgUrl: ''
        }
    },
    methods: {
        genImgUrl,
        getAllList () {
            this.$http.getList(this.$store.state.languageIndex, this.$route.query.index).then(res => {
                console.log('list:', res.data.data)
                this.list = res.data.data
                this.changeVideo(this.list)
            })
        },
        //将后台的数据结合成对应的播放视频所需的
        changeVideo (array) {
            if (this.$route.query.type === '视频资料') {
                for (const key in array) {
                    array[key].video = []
                    array[key].list.forEach(value => {
                        let obj = {
                            height: 400,
                            playbackRates: [0.7, 1.0, 1.5, 2.0],
                            autoplay: false,
                            muted: false,
                            loop: false,
                            preload: 'auto',
                            language: 'zh-CN',
                            aspectRatio: '16:9',
                            fluid: true,
                            sources: [
                                {
                                    type: 'video/mp4',
                                    src: '' // url地址
                                }
                            ],
                            poster: '', // 封面地址
                            notSupportedMessage: '此视频暂无法播放，请稍后再试',
                            controlBar: {
                                timeDivider: true,
                                durationDisplay: true,
                                remainingTimeDisplay: false,
                                fullscreenToggle: true
                            }
                        }
                        if (value.videoUrl !== '') {
                            obj.sources[0].src = genImgUrl(this.baseUrl , value.videoUrl)
                        }
                        obj.poster = genImgUrl(this.baseUrl , value.photoUrl)

                        array[key].video.push(obj)
                    })
                }
            } else {
                console.log('sssss')
            }

        },
        getList () {
            this.$http.getPage(this.$store.state.languageIndex, 12).then(res => {
                console.log("getList: ", res)
                this.meta = res.data.data
                this.imgUrl = res.data.data?.banners[0].ImageUrl
                this.$getMedia()
            })
        }
    },
    created () {
        this.meta.Title = this.$route.query.type
        this.getAllList()
        this.getList()
    },
    watch: {
        '$store.state.language' (newValue, oldValue) {
            this.getList()
            if (newValue) {
                this.title = 'Media'
                this.title1 = 'Media Library'
            } else {
                this.title = '媒体'
                this.title1 = '媒体资料库'
            }
        }
    },
    computed: {
        ...mapState(['baseUrl'])
    }

}
</script>


<style scoped lang="scss">

/*-- -------Library---------- --*/
.library {
    .library_gather {
        padding-top: 6.25rem;
        max-width: 1920px;
        margin: 0 auto;

        .swiper {
            width: 100%;
            //height: 37.6875rem;
            background-color: #F5F5F3;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .entrepot {
            padding: 0 7.5rem;
            box-sizing: border-box;

            &_title {
                font-size: 1.5rem;
                line-height: 4.3125rem;
            }

            &_hot {
                padding: 1.875rem 0;

                &_items {
                    margin-bottom: 2.5rem;
                    .hot_title {
                        font-size: 1.5rem;
                        line-height: 4.3125rem;
                    }

                    .hot-content {
                        display: flex;
                        justify-content: left;
                        flex-wrap: wrap;
                        align-content: space-around;

                        .items {
                            width: 24%;
                            margin-right: 1.3333%;

                            .image {
                                width: 100%;
                                height: 17.0625rem;
                                overflow: hidden;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .title {
                                width: 100%;
                                height: 6.25rem;
                                background-color: #FBFBFB;
                                font-size: 1.5rem;
                                line-height: 6.25rem;
                                text-align: center;
                            }

                            &:nth-child(4n) {
                                margin: 0;
                            }
                        }
                    }
                }

                &_video {
                    .hot_title {
                        font-size: 1.5rem;
                        line-height: 4.3125rem;
                    }

                    .hot-content {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        .video {
                            width: 34.625rem;
                            height: 26.25rem;
                            margin-bottom: 6.875rem;

                            &_video {
                                width: 100%;
                                height: 20.6875rem;
                                background-color: #FBFBFB;
                                position: relative;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }

                                .oval {
                                    width: 2.8125rem;
                                    height: 2.8125rem;
                                    border-radius: 50%;
                                    background: transparent url("../../assets/media/oval.png") no-repeat center center;
                                    background-size: 50% auto;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    margin: auto;
                                    border: 0.0625rem solid #282827;
                                }
                            }

                            &_title {
                                width: 100%;
                                height: 4.75rem;
                                line-height: 4.75rem;
                                font-size: 1.5rem;
                                text-align: center;
                                background-color: #FBFBFB;
                                margin-top: 1.25rem;
                            }
                        }
                    }
                }
            }
        }

        .m_entrepot {
            padding: 0 3.4375rem;

            .entrepot_title {
                padding: 3rem 0;
            }

            .entrepot_hot {
                &_items {
                    .hot_title {
                        font-size: 2.5rem;
                        line-height: 7.3125rem;
                        margin: 0 auto;
                    }

                    .hot-content {
                        .items {
                            width: 100%;
                            margin-bottom: 3.4375rem;

                            .image {
                                width: 100%;
                                height: auto;
                            }

                            .title {
                                padding: 0 1.625rem;
                                text-align: left;
                                box-sizing: border-box;
                            }
                        }
                    }
                }

                &_video {
                    .hot_title {
                        font-size: 3rem;
                        line-height: 6rem;
                    }

                    .hot-content {
                        .video {
                            width: 100%;

                            &_video {
                                background-color: #f5f3ee;
                            }

                            .video_title {
                                padding: 0 1.625rem;
                                text-align: left;
                                box-sizing: border-box;
                                margin: 0;
                                background-color: #fbfbfb;
                            }
                        }

                    }
                }
            }

            ::v-deep .vjs-big-play-button {
                border: 0.0625rem solid #fff;
                background: transparent url("../../assets/media/oval.png") no-repeat center center;
            }
        }
    }
}


::v-deep .vjs-big-play-button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 2.8125rem;
    height: 2.8125rem;
    border-radius: 50%;
    background: transparent url("../../assets/media/oval.png") no-repeat center center;
    background-size: 50% auto;

    span {
        display: none;
    }

    border-color: #000;
}

</style>
